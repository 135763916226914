import { Injectable, signal } from '@angular/core';

import { SectionInfo } from './section-info.interface';

/**
 * SectionInfoService отвечает за управление состоянием информации о секциях.
 * Этот сервис позволяет устанавливать и извлекать данные, связанные с секциями, инкапсулированные в объекте SectionInfo.
 *
 * Экземпляр этого сервиса может быть внедрен и использован для управления деталями, связанными с секциями, в других частях приложения.
 *
 * Свойство sectionInfo предоставляет доступ к реактивному сигналу только для чтения, чтобы наблюдать текущую информацию о секциях.
 *
 * Методы:
 * - setSectionInfo(sectionInfo: SectionInfo | null): Обновляет информацию о секции с предоставленным объектом SectionInfo или устанавливает его в null.
 */
@Injectable({
  providedIn: 'root', //todo: maybe restrict to generative toolbar component only
})
export class SectionInfoService {
  /**
   * Переменная-сигнал, содержащая информацию о конкретной секции.
   *
   * Эта переменная используется для отслеживания состояния или деталей секции в приложении.
   * Она может содержать объект типа `SectionInfo`, представляющий информацию о секции,
   * либо быть `null`, если информация о секции в данный момент недоступна или не имеет значения.
   *
   * @type {signal<SectionInfo|null>}
   */
  readonly #sectionInfo = signal<SectionInfo | null>(null);

  /**
   * Только для чтения представление информации о секции.
   *
   * Эта переменная хранит информацию о конкретной секции
   * и преобразована в формат только для чтения, что гарантирует,
   * что информация о секции не может быть изменена напрямую после
   * ее создания.
   *
   * @type {Readonly<SectionInfo>}
   */
  readonly sectionInfo = this.#sectionInfo.asReadonly();

  /**
   * Устанавливает информацию о секции.
   *
   * @param {SectionInfo | null} sectionInfo - Информация о секции для установки.
   *                                           Может быть экземпляром SectionInfo или равна null.
   * @return {void} Этот метод не возвращает значение.
   */
  setSectionInfo(sectionInfo: SectionInfo | null): void {
    this.#sectionInfo.set(sectionInfo);
  }
}
