import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  computed,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, combineLatest, delay, take } from 'rxjs';

import { ObjectCardService } from './components/object-card/object-card.service';
import { SnapshotService } from './components/snapshot/snapshot.service';
import { ToggleInterfaceVisibilityDirective } from './directives/toggle-interface-visibility/toggle-interface-visibility.directive';
import { Status } from './enums/status.enum';
import { FrontendEventsService } from './services/frontend-events.service';
import { PixelStreamingService } from './services/pixelstreaming.service';
import { PreloaderService } from './services/preloader.service';
import { RouterIsLoadedService } from './services/router-is-loaded.service';
import { ThemeService } from './services/theme.service';
import { UeBrowserEventsService } from './services/ue-browser-events.service';
import { WINDOW } from './tokens/window.token';

/**
 * Класс AppComponent представляет корневой компонент приложения.
 * Он отвечает за инициализацию приложения, управление состоянием аутентификации пользователя,
 * а также подписку на различные события от PixelStreamingService.
 *
 * @component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [ToggleInterfaceVisibilityDirective],
})
export class AppComponent implements OnInit {
  /**
   * Представляет ссылку на элемент-контейнер для видео.
   *
   * @typedef {ElementRef<HTMLElement>} VideoContainer
   * @property {HTMLElement} nativeElement - Соответствующий элемент HTML для контейнера видео.
   */
  @ViewChild('videoContainer', { static: false }) videoContainer?: ElementRef<HTMLElement>;

  /**
   * Переменная isPreloaderVisible$ - это Observable, которая представляет собой статус видимости прелоадера.
   *
   * @type {Observable<boolean>}
   * @name isPreloaderVisible$
   * @memberof [Component/Service Name]
   */
  readonly isPreloaderVisible$: Observable<boolean> = this.preloaderService.isVisible$;

  /**
   * Представляет статус системы или процесса.
   *
   * @class
   * @constructor
   */
  readonly status = Status;

  /**
   * Определяет, видима ли карточка объекта.
   *
   * @функция
   * @returns {boolean} - Верно, если карточка объекта видна, иначе неверно.
   */
  isObjectCardVisible = computed(() => !!this.objectCardService.groupedProperties());
  /**
   * RxJS Observable, представляющий состояние возможности перетаскивания картографического изображения.
   *
   * @type {Observable<boolean>}
   */
  cartographicDragEnabled$ = this.frontendEventsService.cartographicDragEnabled$;
  /**
   * Представляет статус готовности потока.
   *
   * @returns {Observable<boolean>} - An observable that emits a boolean value indicating whether the stream is ready or not.
   */
  readonly isStreamReady$ = this.pixelStreamingService.isStreamReady$;
  /**
   * Представляет Observable поток видимости элемента клика для воспроизведения.
   *
   * @type {BehaviorSubject<boolean>}
   */
  readonly clickToPlayVisible$ = new BehaviorSubject(true);
  /**
   * Представляет текущее состояние входа пользователя в систему.
   *
   * @type {BehaviorSubject<boolean>}
   */
  readonly loggedIn$ = new BehaviorSubject(false);

  /**
   * Проверяет, имеет ли текущее окружение страницу "into".
   *
   * @param {object} environment - Объект окружения, который содержит свойство "hasIntoPage".
   * @return {boolean} - Возвращает true, если текущее окружение имеет страницу "into", в противном случае - false.
   */
  canShowIntoPage = this.snapshotService.showIntoPage;

  /**
   * Внедренный экземпляр DestroyRef.
   *
   * @type {DestroyRef}
   */
  readonly #destroyRef = inject(DestroyRef);
  /**
   * Сервис для работы с событиями браузера.
   * @type {UeBrowserEventsService}
   */
  readonly #ueBrowserEventsService = inject(UeBrowserEventsService);

  /**
   * Конструктор для компонента.
   *
   * @param {Window} window - Ссылка на объект Window.
   * @param {ElementRef} elementRef - Ссылка на элемент, к которому привязан компонент.
   * @param {Title} titleService - Служба для управления заголовком страницы.
   * @param {PixelStreamingService} pixelStreamingService - Служба для работы с пиксельным потоком.
   * @param {ObjectCardService} objectCardService - Служба для работы с картами объектов.
   * @param {ChangeDetectorRef} cdRef - Ссылка на объект ChangeDetectorRef.
   * @param {PreloaderService} preloaderService - Служба для предварительной загрузки данных.
   * @param {ThemeService} themeService - Служба для управления темой приложения.
   * @param {FrontendEventsService} frontendEventsService - Служба для работы с событиями на стороне клиента.
   * @param {SnapshotService} snapshotService - Служба для работы со снимками данных.
   * @param {RouterIsLoadedService} routerIsLoadedService - Служба для определения, загружен ли маршрутизатор.
   *
   * @return {void}
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private elementRef: ElementRef,
    private titleService: Title,
    private pixelStreamingService: PixelStreamingService,
    private objectCardService: ObjectCardService,
    private cdRef: ChangeDetectorRef,
    private preloaderService: PreloaderService,
    private themeService: ThemeService,
    private frontendEventsService: FrontendEventsService,
    private snapshotService: SnapshotService,
    private routerIsLoadedService: RouterIsLoadedService,
  ) {}

  /**
   * Инициализирует компонент, дожидается готовности компонента к вызову методом установки title сайта и установки темы сайта на основе
   * данных из localStorage или tесли данных там нет то по умолчанию ставиться светлая тема
   */
  ngOnInit(): void {
    this.titleService.setTitle(this.window.location.host);
    this.themeService.setThemeInitialValue();
    this.enableUEManagesEvents();
  }

  /**
   * Обновляет статус входа в систему и выполняет необходимые действия.
   *
   * @param {boolean} loggedIn - Новый статус входа в систему.
   * @returns {void}
   */
  loggedInChange(loggedIn: boolean): void {
    if (this.loggedIn$.value !== loggedIn) {
      this.loggedIn$.next(loggedIn);
      this.cdRef.detectChanges();

      if (loggedIn) {
        if (this.videoContainer) {
          this.pixelStreamingService.init(this.videoContainer.nativeElement);
          combineLatest([this.isStreamReady$, this.routerIsLoadedService.routerIsLoaded$])
            .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
            .subscribe(() => {
              this.frontendEventsService.initEvents();
              this.#ueBrowserEventsService.init();
            });
        } else {
          console.error('No videoContainer found');
        }
      }
    }
  }

  /**
   * Метод для изменения видимости "нажать, чтобы играть". Этот метод обновляет значение
   * субъекта clickToPlayVisible$.
   *
   * @param {boolean} value - Новое значение видимости "нажать, чтобы играть".
   * @return {void}
   */
  clickToPlayVisibleChange(value: false): void {
    this.clickToPlayVisible$.next(value);
  }

  /**
   * Активирует маршрут, излучая булево значение, чтобы указать, что
   * роутер загружен.
   *
   * @returns {void}
   */
  activateRoute(): void {
    this.routerIsLoadedService.setRouterIsLoaded(true);
  }

  /**
   * Получить ссылку на элемент
   * @returns {ElementRef} Ссылка на элемент
   */
  getElementRef(): ElementRef {
    return this.elementRef;
  }

  /**
   * Включить UE-вводы.
   * Этот метод включает ввод пользователя для Unreal Engine.
   *
   * @private
   * @returns {void}
   */
  private enableUEManagesEvents(): void {
    this.isStreamReady$.pipe(take(1), delay(0), takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      if (!this.snapshotService.showIntoPage) {
        this.window.location.pathname !== '/search' && this.pixelStreamingService.setInputState(true);
      }
    });
  }
}
