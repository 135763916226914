import { NotificationType } from './notifications.enum';

/**
 * Объект для сопоставления строк генератора уведомлений
 * с конкретными значениями NotificationType.
 *
 * Этот объект Record использует строки для идентификации различных типов уведомлений
 * и связывает их с соответствующими перечислениями NotificationType.
 *
 * Пары ключ-значение:
 * - "error": NotificationType.ERROR
 * - "warning": NotificationType.USER_ACTION
 * - "info": NotificationType.INFO
 */
export const notificationGeneratorToMetaMapper: Record<'error' | 'info' | 'warning', NotificationType> = {
  error: NotificationType.ERROR,
  warning: NotificationType.USER_ACTION,
  info: NotificationType.INFO,
};
