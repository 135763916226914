import { Injectable } from '@angular/core';
import { Observable, filter, map, take } from 'rxjs';
import { share } from 'rxjs/operators';

import { ControlMode } from '../components/control-modes/control-modes.interface';
import { LightType } from '../components/light-toggle-button/light.enum';
import { RpcMessagesBackend } from '../enums/rpc-messages.enum';
import { BuildingCard } from '../modules/toolbar/3dmodels/models3d.interface';
import { GeneratePhaseType } from '../modules/toolbar/generative-toolbar/generative-toolbar-phases.enum';
import { GenerationRequestModel } from '../modules/toolbar/generative-toolbar/subpanels/generate-panel/generate-panel.type';
import { Doc } from '../modules/toolbar/search/search.interface';
import {
  EventData,
  GetControlModeResult,
  GetCurrentBuildingsResult,
  GetGenUrbanDesignStateResult,
  GetGenerationInfoResult,
  GetLandAreaValueResult,
  IsGenUrbanDesignPanelActiveResult,
  MacRightMouseClickParam,
  SUCCESS,
  SearchBuildingsByKeywordsResult,
  SetBuildingsOption,
  SetGlobeUrlParam,
  TeleportCameraToBuildingParam,
  ToggleGenUrbanDesignPanelParam,
  TranslateCameraParam,
  TranslateCameraToBuildingParam,
} from '../types/jsonrpc.interface';
import { PixelStreamingService } from './pixelstreaming.service';

/**
 * Service for handling backend events.
 * @class
 * @public
 */
@Injectable({ providedIn: 'root' })
export class BackendEventsService {
  /**
   * Создает новый экземпляр класса.
   *
   * @param {PixelStreamingService} pixelStreamingService - Сервис потоковой передачи пикселей для внедрения.
   */
  constructor(private pixelStreamingService: PixelStreamingService) {}

  /**
   * Перемещает камеру к указанному зданию.
   *
   * @param {string} buildingId - ID здания, к которому следует переместить камеру.
   *
   * @return {Observable<EventData<{ params: TranslateCameraToBuildingParam }>>} Наблюдаемый объект, который выдает данные события,
   * содержащие параметры для перемещения камеры к зданию.
   */
  translateCameraToBuilding(buildingId: string): Observable<EventData<{ params: TranslateCameraToBuildingParam }>> {
    return this.pixelStreamingService
      .sendRequest<{
        params: TranslateCameraToBuildingParam;
      }>(RpcMessagesBackend.TRANSLATE_CAMERA_TO_BUILDING, {
        buildingId,
      })
      .pipe(share(), take(1));
  }

  /**
   * Телепортирует камеру к определенному зданию.
   *
   * @param {string} buildingId - ID здания.
   * @return {Observable<EventData<{ params: TeleportCameraToBuildingParam }>>} - Наблюдаемый объект, который выдает данные события
   * телепортации.
   */
  teleportCameraToBuilding(buildingId: string): Observable<EventData<{ params: TeleportCameraToBuildingParam }>> {
    return this.pixelStreamingService
      .sendRequest<{
        params: TeleportCameraToBuildingParam;
      }>(RpcMessagesBackend.TELEPORT_CAMERA_TO_BUILDING, { buildingId })
      .pipe(share(), take(1));
  }

  /**
   * Извлекает режим управления из сервиса потокового передачи пикселей.
   *
   * @returns {Observable<ControlMode>} Наблюдаемый объект, который излучает режим управления.
   * @throws {Error} Выбрасывает ошибку, если режим управления не может быть извлечен.
   * @example
   * getControlMode().subscribe((controlMode) => {
   * });
   */
  getControlMode(): Observable<ControlMode> {
    return this.pixelStreamingService.sendRequest<{ result: GetControlModeResult }>(RpcMessagesBackend.GET_CONTROL_MODE).pipe(
      map((data) => {
        if (data.result) {
          return data.result;
        } else {
          throw new Error('UE result getControlMode error, no result');
        }
      }),
      share(),
      take(1),
    );
  }

  /**
   * Устанавливает режим управления.
   *
   * @param {ControlMode} controlMode - Режим управления, который нужно установить.
   * @returns {Observable<SUCCESS>} - Наблюдаемый объект, который излучает результат операции установки режима управления.
   * @throws {Error} - Если ответ не содержит свойства успеха, указывающего на успешную установку режима управления.
   */
  setControlMode(controlMode: ControlMode): Observable<SUCCESS> {
    return this.pixelStreamingService
      .sendRequest<{ params: ControlMode; result: SUCCESS }>(RpcMessagesBackend.SET_CONTROL_MODE, controlMode)
      .pipe(
        map((data) => {
          if (data.result && 'success' in data.result) {
            return data.result;
          } else {
            throw new Error('UE result setControlMode error, no success property in response');
          }
        }),
        share(),
        take(1),
      );
  }

  /**
   * Устанавливает URL ссылки для глобуса в UE.
   *
   * @param {string} value - URL ссылки для глобуса.
   * @returns {void}
   */
  setGlobeUrl(value: string): Observable<SUCCESS> {
    return this.pixelStreamingService
      .sendRequest<{ params: SetGlobeUrlParam; result: SUCCESS }>(RpcMessagesBackend.SET_GLOBE_URL, {
        value,
      })
      .pipe(
        map((data) => {
          if (data.result && 'success' in data.result) {
            return data.result;
          } else {
            throw new Error('UE result setGlobeUrl error, no success property in response');
          }
        }),
        share(),
        take(1),
      );
  }

  /**
   * Выходит из режима размещения персонажей.
   *
   * @returns {Observable<true>} Наблюдаемый объект, который излучает истину, когда успешно выходит из режима размещения персонажей.
   */
  exitCharacterPlacementMode(): Observable<true> {
    return this.pixelStreamingService.sendRequest(RpcMessagesBackend.EXIT_CHARACTER_PLACEMENT_MODE).pipe(map(() => true));
  }

  /**
   * Получает тип освещения.
   *
   * @return {Observable<string>} Наблюдаемый, который излучает строку, представляющую тип освещения.
   *
   * @throws {Error} Если результат работы на backend не содержит допустимого типа освещения.
   */
  getLightType(): Observable<LightType> {
    return this.pixelStreamingService.sendRequest<{ result: { type: LightType } }>(RpcMessagesBackend.GET_LIGHT_TYPE).pipe(
      map((data) => {
        if (data?.result?.type) {
          return data.result.type;
        } else {
          throw new Error('UI result getLightType error');
        }
      }),
      filter((lightType) => !!lightType),
      share(),
      take(1),
    );
  }

  /**
   * Устанавливает тип освещения для сервиса потокового вывода пикселей.
   *
   * @param {LightType} lightType - Тип освещения для установки.
   * @return {Observable<unknown>} - Наблюдаемый объект, который излучает результат операции.
   */
  setLightType(lightType: LightType): Observable<unknown> {
    return this.pixelStreamingService.sendRequest(RpcMessagesBackend.SET_LIGHT_TYPE, { type: lightType }).pipe(share(), take(1));
  }

  /**
   * Устанавливает опцию зданий.
   * @param {boolean} second - Флаг второго здания.
   * @param {string} [buildingId] - Идентификатор здания (необязательный).
   * @param {boolean} [useOldApiBuildingOption] - Идентификатор здания (необязательный).
   * @returns {Observable<{ params: SetBuildingsOption }>} - Observable с параметрами SetBuildingsOption.
   */
  setBuildingsOption(second: boolean, buildingId?: string, useOldApiBuildingOption?: boolean): Observable<{ params: SetBuildingsOption }> {
    return this.pixelStreamingService.sendRequest<{ params: SetBuildingsOption }>(
      RpcMessagesBackend.SET_BUILDINGS_OPTION,
      buildingId ? { buildingId, second, useOldApiBuildingOption } : { second },
    );
  }

  /**
   * Отправляет запрос на выполнение правого клика мышкой на устройстве Mac.
   *
   * @param {boolean} down - Определяет, должна ли правая кнопка мыши быть зажатой или отпущена.
   *
   * @return {Observable<{ params: MacRightMouseClickParam }>} - Объект Observable, который выдает результат запроса.
   */
  macRightMouseClick(down: boolean): Observable<{ params: MacRightMouseClickParam }> {
    return this.pixelStreamingService
      .sendRequest<{ params: MacRightMouseClickParam }>(RpcMessagesBackend.MAC_RIGHT_MOUSE_CLICK, { down })
      .pipe(share(), take(1));
  }

  /**
   * Возвращает текущие здания
   *
   * @return {Observable<BuildingCard[]>} - Объект Observable, который выдает результат запроса
   */
  getCurrentBuildings(): Observable<BuildingCard[]> {
    return this.pixelStreamingService.sendRequest<{ result: GetCurrentBuildingsResult }>(RpcMessagesBackend.GET_CURRENT_BUILDINGS).pipe(
      share(),
      take(1),
      map((data) => data.result?.buildingCards ?? []),
    );
  }

  /**
   * Перемещает камеру в указанное место.
   *
   * @param {Doc} place - Место, в которое должна быть перемещена камера.
   * @return {Observable<EventData<{ params: Doc }>>} Наблюдаемый объект, который выдает ответ от сервера.
   */
  translateCameraToObject(place: Doc): Observable<EventData<{ params?: Doc }>> {
    return this.pixelStreamingService
      .sendRequest<{ params: Doc }>(RpcMessagesBackend.TRANSLATE_CAMERA_TO_OBJECT, place)
      .pipe(share(), take(1));
  }

  /**
   * Ищет здания по ключевым словам.
   *
   * @param {string} query - Поисковый запрос.
   * @return {Observable<BuildingSearchResult[]>} - Наблюдаемый объект, который выдает массив результатов поиска зданий.
   */
  searchBuildingsByKeywords(query: string): Observable<Doc[]> {
    return this.pixelStreamingService
      .sendRequest<{ result: SearchBuildingsByKeywordsResult }>(RpcMessagesBackend.SEARCH_BUILDINGS_BY_KEYWORDS, { query })
      .pipe(
        map(({ result }) => result?.buildings ?? []),
        share(),
        take(1),
      );
  }

  /**
   * Переключает значение PCG (Pixel Color Grading).
   *
   * @param {boolean} value - Новое значение для PCG.
   * @return {Observable<true>} - Наблюдаемый объект, который излучает `true`, когда переключение успешно произведено.
   */
  toggleGenUrbanDesignPanel(value: boolean): Observable<true> {
    return this.pixelStreamingService
      .sendRequest<{ params: ToggleGenUrbanDesignPanelParam }>(RpcMessagesBackend.TOGGLE_GEN_URBAN_DESIGN_PANEL, { value })
      .pipe(
        share(),
        take(1),
        map(() => true),
      );
  }

  /**
   * Проверяет, активен ли панель генерации городского дизайна.
   * @returns {Observable<IsGenUrbanDesignPanelActiveResult>} Observable с результатом проверки активности панели генерации городского дизайна.
   */
  isGenUrbanDesignPanelActive(): Observable<IsGenUrbanDesignPanelActiveResult> {
    return this.pixelStreamingService
      .sendRequest<{ result: IsGenUrbanDesignPanelActiveResult }>(RpcMessagesBackend.IS_GEN_URBAN_DESIGN_PANEL_ACTIVE)
      .pipe(
        map((data) => {
          if (data?.result) {
            return data.result;
          } else {
            throw new Error('UI result isGenUrbanDesignPanelActive error');
          }
        }),
        filter((result) => !!result),
        share(),
        take(1),
      );
  }

  /**
   * Получить значение площади земельного участка.
   * @returns {Observable<GetLandAreaValueResult>} - Поток данных с результатом запроса.
   */
  getLandAreaValue(): Observable<GetLandAreaValueResult> {
    return this.pixelStreamingService.sendRequest<{ result: GetLandAreaValueResult }>(RpcMessagesBackend.GET_LAND_AREA_VALUE).pipe(
      map((data) => {
        if (data?.result?.value) {
          return data.result;
        } else {
          throw new Error('UI empty or incorrect result getLandAreaValue value');
        }
      }),
      filter((result) => !!result),
      share(),
      take(1),
    );
  }

  /**
   * Получает состояние общего генерального плана городского дизайна.
   * @returns {Observable<GetGenUrbanDesignStateResult>} Поток данных с результатом запроса состояния генерального плана городского дизайна.
   */
  getGenUrbanDesignState(): Observable<GetGenUrbanDesignStateResult> {
    return this.pixelStreamingService
      .sendRequest<{ result: GetGenUrbanDesignStateResult }>(RpcMessagesBackend.GET_GEN_URBAN_DESIGN_STATE)
      .pipe(
        map((data) => {
          if (data?.result) {
            return data.result;
          } else {
            throw new Error('UI result getGenUrbanDesignState error');
          }
        }),
        filter((result) => !!result),
        share(),
        take(1),
      );
  }

  /**
   * Устанавливает состояние генерации городского дизайна.
   * @param {GeneratePhaseType} generationPhase - Фаза генерации
   * @returns {Observable<SUCCESS>} - Observable с результатом успешного выполнения
   */
  setGenUrbanDesignState(generationPhase: GeneratePhaseType): Observable<SUCCESS> {
    return this.pixelStreamingService
      .sendRequest<{ result: SUCCESS }>(RpcMessagesBackend.SET_GEN_URBAN_DESIGN_STATE, { generationPhase })
      .pipe(
        map(() => {
          return { success: true };
        }),
      );
  }

  /**
   * Уничтожает область и возвращает Observable с успешным результатом
   * @returns {Observable<SUCCESS>} Observable с успешным результатом
   */
  destroyArea(): Observable<SUCCESS> {
    return this.pixelStreamingService.sendRequest<{ result: SUCCESS }>(RpcMessagesBackend.DESTROY_AREA).pipe(
      map((data) => {
        if (data.result && 'success' in data.result && data.result.success) {
          return data.result;
        } else {
          throw new Error('UE result destroyArea error');
        }
      }),
    );
  }

  /**
   * Генерирует область на основе переданных параметров
   * @param {GenerationRequestModel} parametersFormParams - Параметры для генерации области
   * @returns {Observable<SUCCESS>} - Observable с результатом генерации области
   */
  generateArea(parametersFormParams: GenerationRequestModel): Observable<SUCCESS> {
    return this.pixelStreamingService
      .sendRequest<{ result: SUCCESS }>(RpcMessagesBackend.GENERATE_AREA, { request: parametersFormParams })
      .pipe(
        map((data) => {
          if (data.result && 'success' in data.result && data.result.success) {
            return data.result;
          } else {
            throw new Error('UE result generateArea error');
          }
        }),
      );
  }

  /**
   * Получает информацию о поколении.
   * @returns {Observable<GetGenerationInfoResult>} Observable, который возвращает информацию о поколении.
   */
  getGenerationInfo(): Observable<GetGenerationInfoResult> {
    return this.pixelStreamingService.sendRequest<{ result: GetGenerationInfoResult }>(RpcMessagesBackend.GET_GENERATION_INFO).pipe(
      map((data) => {
        if (data?.result) {
          return data.result;
        } else {
          throw new Error('UI result getGenerationInfo error');
        }
      }),
      filter((result) => !!result),
      share(),
      take(1),
    );
  }

  /**
   * Переводит координаты и вращение камеры.
   *
   * @param {TranslateCameraParam} options - Объект параметров, содержащий координаты и вращение.
   * @param {Array<number>} options.coordinates - Новые координаты камеры.
   * @param {Array<number>} options.rotation - Новое вращение камеры.
   *
   * @return {Observable<SUCCESS>} Наблюдаемый объект, который излучает значение успеха, когда перевод камеры успешно завершен.
   * @throws {Error} Генерирует ошибку, если ответ не содержит свойства 'success'.
   */
  translateCamera({ coordinates, rotation }: TranslateCameraParam): Observable<SUCCESS> {
    return this.pixelStreamingService
      .sendRequest<{ result: SUCCESS }>(RpcMessagesBackend.TRANSLATE_CAMERA, {
        coordinates,
        rotation,
      })
      .pipe(
        map((data) => {
          if (data.result && 'success' in data.result) {
            return data.result;
          } else {
            throw new Error('UE result translateCamera error, no success property in response');
          }
        }),
      );
  }

  /**
   * Отменяет последнее выполненное действие, отправляя запрос на сервер.
   * Использует сервис Pixel Streaming для взаимодействия с бэкендом.
   *
   * @return {Observable<true>} Наблюдаемый объект, который излучает `true` при успешном завершении операции отмены.
   */
  undoLastAction(): Observable<true> {
    return this.pixelStreamingService.sendRequest(RpcMessagesBackend.UNDO_LAST_ACTION).pipe(map(() => true));
  }
}
