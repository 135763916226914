import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { fromEvent, mergeMap } from 'rxjs';

import { RpcMessagesBackend, RpcMessagesFrontend } from '../enums/rpc-messages.enum';
import { PixelStreamingService } from './pixelstreaming.service';

/**
 * Сервис для обработки событий браузера.
 * @constructor
 */
@Injectable({ providedIn: 'root' })
export class UeBrowserEventsService {
  /**
   * Устанавливает документ в качестве только для чтения
   * @type {DOCUMENT}
   */
  readonly #document = inject(DOCUMENT);

  /**
   * Инициализирует сервис для передачи пикселей.
   * @param {PixelStreamingService} pixelStreamingService - Сервис для передачи пикселей.
   */
  #pixelStreamingService = inject(PixelStreamingService);

  /**
   * Инициализирует обработчики событий для бэкенда и фронтенда.
   * @returns {void}
   */
  init(): void {
    this.backendEvents();
    this.frontendEvents();
  }

  // $0.dispatchEvent(new CustomEvent('backendEvent', { detail: { method: 'translateCameraToBuilding', params: { buildingId: '5C26AC914E739F46B9851A87E5F2281B' } } }));
  /**
   * Приватный метод для обработки событий от бэкенда.
   * Подписывается на событие 'backendEvent' на элементе body документа.
   * При получении события отправляет запрос на сервис pixelStreamingService с указанным методом и параметрами.
   */
  private backendEvents(): void {
    fromEvent<CustomEvent<{ method: string; params: Record<string, unknown> }>>(this.#document.body, 'backendEvent')
      .pipe(mergeMap(({ detail: { method, params } }) => this.#pixelStreamingService.sendRequest(method as RpcMessagesBackend, params)))
      .subscribe();
  }

  // $0.dispatchEvent(new CustomEvent('frontendEvent', { detail: { method: 'updateLightActivationType' } }));
  /**
   * Обрабатывает события, происходящие на фронтенде.
   * @private
   * @returns {void}
   */
  private frontendEvents(): void {
    fromEvent<CustomEvent<{ method: string; params: Record<string, unknown> }>>(this.#document.body, 'frontendEvent')
      .pipe(mergeMap(({ detail: { method } }) => this.#pixelStreamingService.on(method as RpcMessagesFrontend)))
      .subscribe();
  }
}
